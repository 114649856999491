export const VRouterLink = () => import('../../components/VRouterLink.vue' /* webpackChunkName: "components/v-router-link" */).then(c => wrapFunctional(c.default || c))
export const ArtistsArtistCardFrame = () => import('../../components/artists/artistCardFrame.vue' /* webpackChunkName: "components/artists-artist-card-frame" */).then(c => wrapFunctional(c.default || c))
export const ArtistsArtistInfo = () => import('../../components/artists/artistInfo.vue' /* webpackChunkName: "components/artists-artist-info" */).then(c => wrapFunctional(c.default || c))
export const ArtistsArtistPhoto = () => import('../../components/artists/artistPhoto.vue' /* webpackChunkName: "components/artists-artist-photo" */).then(c => wrapFunctional(c.default || c))
export const ArtistsArtistVideo = () => import('../../components/artists/artistVideo.vue' /* webpackChunkName: "components/artists-artist-video" */).then(c => wrapFunctional(c.default || c))
export const ArtistsArtistdetailinfo = () => import('../../components/artists/artistdetailinfo.vue' /* webpackChunkName: "components/artists-artistdetailinfo" */).then(c => wrapFunctional(c.default || c))
export const ArtistsArtistjoinrecord = () => import('../../components/artists/artistjoinrecord.vue' /* webpackChunkName: "components/artists-artistjoinrecord" */).then(c => wrapFunctional(c.default || c))
export const ArtistsArtistnews = () => import('../../components/artists/artistnews.vue' /* webpackChunkName: "components/artists-artistnews" */).then(c => wrapFunctional(c.default || c))
export const ArtistsArtistofficialsite = () => import('../../components/artists/artistofficialsite.vue' /* webpackChunkName: "components/artists-artistofficialsite" */).then(c => wrapFunctional(c.default || c))
export const ArtistsArtistofficialtwitter = () => import('../../components/artists/artistofficialtwitter.vue' /* webpackChunkName: "components/artists-artistofficialtwitter" */).then(c => wrapFunctional(c.default || c))
export const ArtistsArtistofficialyoutube = () => import('../../components/artists/artistofficialyoutube.vue' /* webpackChunkName: "components/artists-artistofficialyoutube" */).then(c => wrapFunctional(c.default || c))
export const ArtistsArtistproject = () => import('../../components/artists/artistproject.vue' /* webpackChunkName: "components/artists-artistproject" */).then(c => wrapFunctional(c.default || c))
export const ArtistsCardVideoThumbnail = () => import('../../components/artists/cardVideoThumbnail.vue' /* webpackChunkName: "components/artists-card-video-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const ArtistsLogo = () => import('../../components/artists/logo.vue' /* webpackChunkName: "components/artists-logo" */).then(c => wrapFunctional(c.default || c))
export const ArtistsMemberProfile = () => import('../../components/artists/memberProfile.vue' /* webpackChunkName: "components/artists-member-profile" */).then(c => wrapFunctional(c.default || c))
export const ArtistsModalArtist = () => import('../../components/artists/modalArtist.vue' /* webpackChunkName: "components/artists-modal-artist" */).then(c => wrapFunctional(c.default || c))
export const ArtistsModalPicture = () => import('../../components/artists/modalPicture.vue' /* webpackChunkName: "components/artists-modal-picture" */).then(c => wrapFunctional(c.default || c))
export const ArtistsModalVideo = () => import('../../components/artists/modalVideo.vue' /* webpackChunkName: "components/artists-modal-video" */).then(c => wrapFunctional(c.default || c))
export const ArtistsOfficialsite = () => import('../../components/artists/officialsite.vue' /* webpackChunkName: "components/artists-officialsite" */).then(c => wrapFunctional(c.default || c))
export const ArtistsRealtimehashtag = () => import('../../components/artists/realtimehashtag.vue' /* webpackChunkName: "components/artists-realtimehashtag" */).then(c => wrapFunctional(c.default || c))
export const ArtistsSoloinfo = () => import('../../components/artists/soloinfo.vue' /* webpackChunkName: "components/artists-soloinfo" */).then(c => wrapFunctional(c.default || c))
export const ArtistsSoloprofile = () => import('../../components/artists/soloprofile.vue' /* webpackChunkName: "components/artists-soloprofile" */).then(c => wrapFunctional(c.default || c))
export const ArtistsWikipedia = () => import('../../components/artists/wikipedia.vue' /* webpackChunkName: "components/artists-wikipedia" */).then(c => wrapFunctional(c.default || c))
export const CommonAgeRestrictedImage = () => import('../../components/common/AgeRestrictedImage.vue' /* webpackChunkName: "components/common-age-restricted-image" */).then(c => wrapFunctional(c.default || c))
export const CommonCardFrame = () => import('../../components/common/CardFrame.vue' /* webpackChunkName: "components/common-card-frame" */).then(c => wrapFunctional(c.default || c))
export const CommonCardProgressbar = () => import('../../components/common/Card_Progressbar.vue' /* webpackChunkName: "components/common-card-progressbar" */).then(c => wrapFunctional(c.default || c))
export const CommonDialog = () => import('../../components/common/Dialog.vue' /* webpackChunkName: "components/common-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommonImage = () => import('../../components/common/Image.vue' /* webpackChunkName: "components/common-image" */).then(c => wrapFunctional(c.default || c))
export const CommonPopOver = () => import('../../components/common/PopOver.vue' /* webpackChunkName: "components/common-pop-over" */).then(c => wrapFunctional(c.default || c))
export const CommonPopOver2 = () => import('../../components/common/PopOver2.vue' /* webpackChunkName: "components/common-pop-over2" */).then(c => wrapFunctional(c.default || c))
export const CommonReCaptcha = () => import('../../components/common/ReCaptcha.vue' /* webpackChunkName: "components/common-re-captcha" */).then(c => wrapFunctional(c.default || c))
export const CommonSectionHeader = () => import('../../components/common/SectionHeader.vue' /* webpackChunkName: "components/common-section-header" */).then(c => wrapFunctional(c.default || c))
export const CommonSwiperFrame = () => import('../../components/common/SwiperFrame.vue' /* webpackChunkName: "components/common-swiper-frame" */).then(c => wrapFunctional(c.default || c))
export const CommonTriggerObserver = () => import('../../components/common/TriggerObserver.vue' /* webpackChunkName: "components/common-trigger-observer" */).then(c => wrapFunctional(c.default || c))
export const CommonBankSelectBox = () => import('../../components/common/bankSelectBox.vue' /* webpackChunkName: "components/common-bank-select-box" */).then(c => wrapFunctional(c.default || c))
export const CommonCheckBox = () => import('../../components/common/checkBox.vue' /* webpackChunkName: "components/common-check-box" */).then(c => wrapFunctional(c.default || c))
export const CommonComment = () => import('../../components/common/comment.vue' /* webpackChunkName: "components/common-comment" */).then(c => wrapFunctional(c.default || c))
export const CommonCommentBox = () => import('../../components/common/commentBox.vue' /* webpackChunkName: "components/common-comment-box" */).then(c => wrapFunctional(c.default || c))
export const CommonCommentInput = () => import('../../components/common/commentInput.vue' /* webpackChunkName: "components/common-comment-input" */).then(c => wrapFunctional(c.default || c))
export const CommonDialogAlert = () => import('../../components/common/dialogAlert.vue' /* webpackChunkName: "components/common-dialog-alert" */).then(c => wrapFunctional(c.default || c))
export const CommonFileInput = () => import('../../components/common/fileInput.vue' /* webpackChunkName: "components/common-file-input" */).then(c => wrapFunctional(c.default || c))
export const CommonFileUpload = () => import('../../components/common/fileUpload.vue' /* webpackChunkName: "components/common-file-upload" */).then(c => wrapFunctional(c.default || c))
export const CommonGifViewer = () => import('../../components/common/gifViewer.vue' /* webpackChunkName: "components/common-gif-viewer" */).then(c => wrapFunctional(c.default || c))
export const CommonModalLeave = () => import('../../components/common/modalLeave.vue' /* webpackChunkName: "components/common-modal-leave" */).then(c => wrapFunctional(c.default || c))
export const CommonPhoneNumberInput = () => import('../../components/common/phoneNumberInput.vue' /* webpackChunkName: "components/common-phone-number-input" */).then(c => wrapFunctional(c.default || c))
export const CommonRadioButton = () => import('../../components/common/radioButton.vue' /* webpackChunkName: "components/common-radio-button" */).then(c => wrapFunctional(c.default || c))
export const CommonSelect = () => import('../../components/common/select.vue' /* webpackChunkName: "components/common-select" */).then(c => wrapFunctional(c.default || c))
export const CommonSkeleTon = () => import('../../components/common/skeleTon.vue' /* webpackChunkName: "components/common-skele-ton" */).then(c => wrapFunctional(c.default || c))
export const CommonSns = () => import('../../components/common/sns.vue' /* webpackChunkName: "components/common-sns" */).then(c => wrapFunctional(c.default || c))
export const CommonSwiper = () => import('../../components/common/swiper.vue' /* webpackChunkName: "components/common-swiper" */).then(c => wrapFunctional(c.default || c))
export const CommonTextArea2 = () => import('../../components/common/textArea2.vue' /* webpackChunkName: "components/common-text-area2" */).then(c => wrapFunctional(c.default || c))
export const ContentsArtistCard = () => import('../../components/contents/artistCard.vue' /* webpackChunkName: "components/contents-artist-card" */).then(c => wrapFunctional(c.default || c))
export const FaqInputWrapper2 = () => import('../../components/faq/inputWrapper2.vue' /* webpackChunkName: "components/faq-input-wrapper2" */).then(c => wrapFunctional(c.default || c))
export const ForumsDownArrow = () => import('../../components/forums/downArrow.vue' /* webpackChunkName: "components/forums-down-arrow" */).then(c => wrapFunctional(c.default || c))
export const ForumsPagination = () => import('../../components/forums/pagination.vue' /* webpackChunkName: "components/forums-pagination" */).then(c => wrapFunctional(c.default || c))
export const ForumsSelect = () => import('../../components/forums/select.vue' /* webpackChunkName: "components/forums-select" */).then(c => wrapFunctional(c.default || c))
export const ForumsSelectInput = () => import('../../components/forums/selectInput.vue' /* webpackChunkName: "components/forums-select-input" */).then(c => wrapFunctional(c.default || c))
export const IconIcons = () => import('../../components/icon/Icons.js' /* webpackChunkName: "components/icon-icons" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/icon/icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const LayoutsMainFooter = () => import('../../components/layouts/mainFooter.vue' /* webpackChunkName: "components/layouts-main-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsMainHeader = () => import('../../components/layouts/mainHeader.vue' /* webpackChunkName: "components/layouts-main-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutsSimpleHeader = () => import('../../components/layouts/simpleHeader.vue' /* webpackChunkName: "components/layouts-simple-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutsSiteNoticeDialog = () => import('../../components/layouts/siteNoticeDialog.vue' /* webpackChunkName: "components/layouts-site-notice-dialog" */).then(c => wrapFunctional(c.default || c))
export const LayoutsWaitingRoom = () => import('../../components/layouts/waitingRoom.vue' /* webpackChunkName: "components/layouts-waiting-room" */).then(c => wrapFunctional(c.default || c))
export const PaymentBirthday = () => import('../../components/payment/birthday.vue' /* webpackChunkName: "components/payment-birthday" */).then(c => wrapFunctional(c.default || c))
export const PaymentDaumDialog = () => import('../../components/payment/daumDialog.vue' /* webpackChunkName: "components/payment-daum-dialog" */).then(c => wrapFunctional(c.default || c))
export const PaymentDeliveryDialog = () => import('../../components/payment/deliveryDialog.vue' /* webpackChunkName: "components/payment-delivery-dialog" */).then(c => wrapFunctional(c.default || c))
export const PaymentMessenger = () => import('../../components/payment/messenger.vue' /* webpackChunkName: "components/payment-messenger" */).then(c => wrapFunctional(c.default || c))
export const PaymentPhoneNumberInput = () => import('../../components/payment/phoneNumberInput.vue' /* webpackChunkName: "components/payment-phone-number-input" */).then(c => wrapFunctional(c.default || c))
export const PaymentSelectVuex = () => import('../../components/payment/selectVuex.vue' /* webpackChunkName: "components/payment-select-vuex" */).then(c => wrapFunctional(c.default || c))
export const PaymentTextArea = () => import('../../components/payment/textArea.vue' /* webpackChunkName: "components/payment-text-area" */).then(c => wrapFunctional(c.default || c))
export const PaymentTextField = () => import('../../components/payment/textField.vue' /* webpackChunkName: "components/payment-text-field" */).then(c => wrapFunctional(c.default || c))
export const PaymentValidationDialog = () => import('../../components/payment/validationDialog.vue' /* webpackChunkName: "components/payment-validation-dialog" */).then(c => wrapFunctional(c.default || c))
export const ProjectsCard = () => import('../../components/projects/card.vue' /* webpackChunkName: "components/projects-card" */).then(c => wrapFunctional(c.default || c))
export const ProjectsDatePicker = () => import('../../components/projects/datePicker.vue' /* webpackChunkName: "components/projects-date-picker" */).then(c => wrapFunctional(c.default || c))
export const ProjectsHeader = () => import('../../components/projects/header.vue' /* webpackChunkName: "components/projects-header" */).then(c => wrapFunctional(c.default || c))
export const ProjectsNotice = () => import('../../components/projects/notice.vue' /* webpackChunkName: "components/projects-notice" */).then(c => wrapFunctional(c.default || c))
export const ProjectsPopOver = () => import('../../components/projects/popOver.vue' /* webpackChunkName: "components/projects-pop-over" */).then(c => wrapFunctional(c.default || c))
export const ProjectsProgress = () => import('../../components/projects/progress.vue' /* webpackChunkName: "components/projects-progress" */).then(c => wrapFunctional(c.default || c))
export const ProjectsSelect = () => import('../../components/projects/select.vue' /* webpackChunkName: "components/projects-select" */).then(c => wrapFunctional(c.default || c))
export const ProjectsSelectProps = () => import('../../components/projects/selectProps.vue' /* webpackChunkName: "components/projects-select-props" */).then(c => wrapFunctional(c.default || c))
export const ProjectsSelectVuex = () => import('../../components/projects/selectVuex.vue' /* webpackChunkName: "components/projects-select-vuex" */).then(c => wrapFunctional(c.default || c))
export const ProjectsTimer = () => import('../../components/projects/timer.vue' /* webpackChunkName: "components/projects-timer" */).then(c => wrapFunctional(c.default || c))
export const ProjectsUpdateDialog = () => import('../../components/projects/updateDialog.vue' /* webpackChunkName: "components/projects-update-dialog" */).then(c => wrapFunctional(c.default || c))
export const QnaAttachment = () => import('../../components/qna/attachment.vue' /* webpackChunkName: "components/qna-attachment" */).then(c => wrapFunctional(c.default || c))
export const QnaFileInput = () => import('../../components/qna/fileInput.vue' /* webpackChunkName: "components/qna-file-input" */).then(c => wrapFunctional(c.default || c))
export const QnaFileUpload = () => import('../../components/qna/fileUpload.vue' /* webpackChunkName: "components/qna-file-upload" */).then(c => wrapFunctional(c.default || c))
export const ShopCard = () => import('../../components/shop/card.vue' /* webpackChunkName: "components/shop-card" */).then(c => wrapFunctional(c.default || c))
export const ShopDate = () => import('../../components/shop/date.vue' /* webpackChunkName: "components/shop-date" */).then(c => wrapFunctional(c.default || c))
export const ShopDateCountContainer = () => import('../../components/shop/dateCountContainer.vue' /* webpackChunkName: "components/shop-date-count-container" */).then(c => wrapFunctional(c.default || c))
export const ShopSaleCount = () => import('../../components/shop/saleCount.vue' /* webpackChunkName: "components/shop-sale-count" */).then(c => wrapFunctional(c.default || c))
export const ShopShopify = () => import('../../components/shop/shopify.vue' /* webpackChunkName: "components/shop-shopify" */).then(c => wrapFunctional(c.default || c))
export const ShopSocial = () => import('../../components/shop/social.vue' /* webpackChunkName: "components/shop-social" */).then(c => wrapFunctional(c.default || c))
export const ShopUsshopopup = () => import('../../components/shop/usshopopup.vue' /* webpackChunkName: "components/shop-usshopopup" */).then(c => wrapFunctional(c.default || c))
export const SignupInfobox = () => import('../../components/signup/infobox.vue' /* webpackChunkName: "components/signup-infobox" */).then(c => wrapFunctional(c.default || c))
export const SignupTermsBox = () => import('../../components/signup/termsBox.vue' /* webpackChunkName: "components/signup-terms-box" */).then(c => wrapFunctional(c.default || c))
export const ToastContent = () => import('../../components/toast/toastContent.vue' /* webpackChunkName: "components/toast-content" */).then(c => wrapFunctional(c.default || c))
export const LoadingLoadingInstance = () => import('../../components/Loading/LoadingInstance/index.vue' /* webpackChunkName: "components/loading-loading-instance" */).then(c => wrapFunctional(c.default || c))
export const ProjectsDescriptionPaymentSetp2 = () => import('../../components/projects/description/paymentSetp2.vue' /* webpackChunkName: "components/projects-description-payment-setp2" */).then(c => wrapFunctional(c.default || c))
export const ProjectsDialogOrderOverload = () => import('../../components/projects/dialog/orderOverload.vue' /* webpackChunkName: "components/projects-dialog-order-overload" */).then(c => wrapFunctional(c.default || c))
export const ProjectsDialogSfexpress = () => import('../../components/projects/dialog/sfexpress.vue' /* webpackChunkName: "components/projects-dialog-sfexpress" */).then(c => wrapFunctional(c.default || c))
export const ProjectsDialogSponsor = () => import('../../components/projects/dialog/sponsor.vue' /* webpackChunkName: "components/projects-dialog-sponsor" */).then(c => wrapFunctional(c.default || c))
export const ProjectsDialogTaiwanDhl = () => import('../../components/projects/dialog/taiwanDhl.vue' /* webpackChunkName: "components/projects-dialog-taiwan-dhl" */).then(c => wrapFunctional(c.default || c))
export const ProjectsDialogUserInfoInsert = () => import('../../components/projects/dialog/userInfoInsert.vue' /* webpackChunkName: "components/projects-dialog-user-info-insert" */).then(c => wrapFunctional(c.default || c))
export const ProjectsDialogValidation = () => import('../../components/projects/dialog/validation.vue' /* webpackChunkName: "components/projects-dialog-validation" */).then(c => wrapFunctional(c.default || c))
export const ProjectsTableCol = () => import('../../components/projects/table/col.vue' /* webpackChunkName: "components/projects-table-col" */).then(c => wrapFunctional(c.default || c))
export const ProjectsTableRow = () => import('../../components/projects/table/row.vue' /* webpackChunkName: "components/projects-table-row" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
