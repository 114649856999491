import React, { useState, useContext } from 'react'
import styled from '@emotion/styled'
import codeConst from '../../../../utils/codeConst'
import { B2Medium, C1Medium, B1Medium } from '~/react/Foundation/Typography'
import { getNotAvailableForSaleText } from '~/utils/shop'
import i18nContext from '../../../i18nContext'
import { CardEmptyImage } from '~/react/Common/Card/CardEmptyImage'
import { ImageResizeTag } from '~/react/common'

const { SALE_STAT } = codeConst

const CardShopImageContainer = styled.div({}, ({}) => {
  const baseStyle = {
    // .card-item
    width : '272px',
    aspectRatio: '1',
    // height: size.pc.height,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    // .relative-content 332
    boxShadow: 'none',
    transition: 'box-shadow 0.5s',

    // .image-hover-zoom 347
    overflow: 'hidden',

    // .card-thumbnail
    position: 'relative',
    flexShrink: '0',
    border: '0.5px solid #ccc',
    borderRadius: '4px'
  }

  const mobileStyle = {
    width : '100%',
    aspectRatio: '1',
    borderBottom: 'solid 1px #cccccc'
  }
  return {
    ...baseStyle,
    ...mobileStyle
  }
})

const CardShopImageBody = styled.img({
  '&:hover': {
      transform: 'scale(1.1)'
  }
}, ({}) => {
  const baseStyle = {
    position: 'absolute',
    height: '100%',
    top: '0',
    left: '0',
    display: 'flex',
    width: '100%',
    transition: 'all 0.5s ease',
    borderRadius: '4px'
  }
  const mobileStyle = {
    objectFit: 'cover',
  }
  return {
    ...baseStyle,
    ...mobileStyle
  }
})

const DimLayer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 4px 4px 0 0;
  background: rgba(0, 0, 0, 0.30);
  z-index: 2;
`

const RestrictedImage = styled.img`
  display: flex;
  justify-content: center;
  width: 108px;
  height: 108px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  aspect-ratio: 1;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  cursor: pointer;
`

const AgeRestrictedText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  color: #888888;
`

const RestrictedImageLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;

  // 썸네일 이미지 완전히 가림 처리
  background-color: white;
`

const createNAText = (baseComponent) => styled(baseComponent)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 10;
`

const NATextB2Medium = createNAText(B2Medium)

const NATextC1Medium = createNAText(C1Medium)

const NATextB1Medium = createNAText(B1Medium)

export function CardShopImage({ imageUrl, size, saleStatus, isAgeRestricted = false, children }) {

  const imgProps = ImageResizeTag({
    imageUrl: imageUrl,
  })

  const i18n = useContext(i18nContext)

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const isNotAvailableForSale = () => {
    return saleStatus !== SALE_STAT.SALE
  }

  const renderNAText = () => {
    const NAText = i18n.t(getNotAvailableForSaleText(saleStatus))
    if (size === 'small') {
      return (
        <>
          <NATextC1Medium as="p">
            {NAText}
          </NATextC1Medium>
          <DimLayer></DimLayer>
        </>
      )
    }
    if (size === 'medium') {
      return (
        <>
          <NATextB2Medium as="p">
            {NAText}
          </NATextB2Medium>
          <DimLayer></DimLayer>
        </>
      )
    }
    return (
      <>
        <NATextB1Medium as="p">
          {NAText}
        </NATextB1Medium>
        <DimLayer></DimLayer>
      </>
    )
  }

  const renderAgeRestrictedImage = () => {
    return (
      <RestrictedImageLayer>
        <RestrictedImage src={'/image/svg-icon/19_age_restricted.svg'}/>
        <AgeRestrictedText>{i18n.t('shop.19warning')}</AgeRestrictedText>
      </RestrictedImageLayer>
    )
  }

  return (
    <>
      <CardShopImageContainer>
        {imageError ? (
          <CardEmptyImage />
        ) : (
          <CardShopImageBody {...imgProps} onError={handleImageError}></CardShopImageBody>
        )}
        {isAgeRestricted && renderAgeRestrictedImage()}
        {isNotAvailableForSale() &&
          renderNAText()}
        {children}
      </CardShopImageContainer>
    </>
  )
}
