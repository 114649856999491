import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _93d3f334 = () => interopDefault(import('../pages/artists/index.vue' /* webpackChunkName: "pages/artists/index" */))
const _0c4d3978 = () => interopDefault(import('../pages/chart/index.vue' /* webpackChunkName: "pages/chart/index" */))
const _6b4a3a70 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _0a10cb6a = () => interopDefault(import('../pages/lb-health.vue' /* webpackChunkName: "pages/lb-health" */))
const _f99eae80 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0207a211 = () => interopDefault(import('../pages/notice.vue' /* webpackChunkName: "pages/notice" */))
const _7762db9b = () => interopDefault(import('../pages/partnership.vue' /* webpackChunkName: "pages/partnership" */))
const _42ae5b3b = () => interopDefault(import('../pages/password-retrieval.vue' /* webpackChunkName: "pages/password-retrieval" */))
const _c74028d4 = () => interopDefault(import('../pages/poca.vue' /* webpackChunkName: "pages/poca" */))
const _763e8bdc = () => interopDefault(import('../pages/powerofwhelm.vue' /* webpackChunkName: "pages/powerofwhelm" */))
const _7eb48884 = () => interopDefault(import('../pages/qna/index.vue' /* webpackChunkName: "pages/qna/index" */))
const _a711bab8 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _56ef4ebd = () => interopDefault(import('../pages/specificCommercialLaw.vue' /* webpackChunkName: "pages/specificCommercialLaw" */))
const _0fdc2fd0 = () => interopDefault(import('../pages/userqna.vue' /* webpackChunkName: "pages/userqna" */))
const _25c869a6 = () => interopDefault(import('../pages/vote/index.vue' /* webpackChunkName: "pages/vote/index" */))
const _039af18a = () => interopDefault(import('../pages/about/marketing.vue' /* webpackChunkName: "pages/about/marketing" */))
const _1b3b9add = () => interopDefault(import('../pages/about/privacy.vue' /* webpackChunkName: "pages/about/privacy" */))
const _4cbe96c8 = () => interopDefault(import('../pages/about/terms.vue' /* webpackChunkName: "pages/about/terms" */))
const _3275be5c = () => interopDefault(import('../pages/payment/order.vue' /* webpackChunkName: "pages/payment/order" */))
const _42d3d5d0 = () => interopDefault(import('../pages/payment/paymentRefundStep.vue' /* webpackChunkName: "pages/payment/paymentRefundStep" */))
const _1777916c = () => interopDefault(import('../pages/payment/paymentRefundStep2.vue' /* webpackChunkName: "pages/payment/paymentRefundStep2" */))
const _c112b63a = () => interopDefault(import('../pages/popup/invoiceHistory.vue' /* webpackChunkName: "pages/popup/invoiceHistory" */))
const _4d8cfe66 = () => interopDefault(import('../pages/projects/paymentStep3.vue' /* webpackChunkName: "pages/projects/paymentStep3" */))
const _7fa0c8a4 = () => interopDefault(import('../pages/projects/updates.vue' /* webpackChunkName: "pages/projects/updates" */))
const _395c2a11 = () => interopDefault(import('../pages/projects/wishlist.vue' /* webpackChunkName: "pages/projects/wishlist" */))
const _54e392c4 = () => interopDefault(import('../pages/projects/wishlist_origin.vue' /* webpackChunkName: "pages/projects/wishlist_origin" */))
const _639769f0 = () => interopDefault(import('../pages/result/change-email.vue' /* webpackChunkName: "pages/result/change-email" */))
const _666f4c5f = () => interopDefault(import('../pages/result/change-password.vue' /* webpackChunkName: "pages/result/change-password" */))
const _448aca44 = () => interopDefault(import('../pages/result/duplication-email.vue' /* webpackChunkName: "pages/result/duplication-email" */))
const _177add66 = () => interopDefault(import('../pages/result/invalid-email.vue' /* webpackChunkName: "pages/result/invalid-email" */))
const _4ef681d2 = () => interopDefault(import('../pages/result/leaveres.vue' /* webpackChunkName: "pages/result/leaveres" */))
const _12b7180b = () => interopDefault(import('../pages/result/password-retrieval.vue' /* webpackChunkName: "pages/result/password-retrieval" */))
const _7d2bbcc2 = () => interopDefault(import('../pages/shop/B2BAccessOnly.vue' /* webpackChunkName: "pages/shop/B2BAccessOnly" */))
const _03bf4470 = () => interopDefault(import('../pages/shop/cart.vue' /* webpackChunkName: "pages/shop/cart" */))
const _24fbafe6 = () => interopDefault(import('../pages/shop/payment.vue' /* webpackChunkName: "pages/shop/payment" */))
const _7a91f63c = () => interopDefault(import('../pages/shop/payment2.vue' /* webpackChunkName: "pages/shop/payment2" */))
const _4f848698 = () => interopDefault(import('../pages/shop/products.vue' /* webpackChunkName: "pages/shop/products" */))
const _9b29ba5c = () => interopDefault(import('../pages/signup/send-auth/index.vue' /* webpackChunkName: "pages/signup/send-auth/index" */))
const _6e8672c5 = () => interopDefault(import('../pages/signup/send-email.vue' /* webpackChunkName: "pages/signup/send-email" */))
const _54650aa7 = () => interopDefault(import('../pages/signup/step1.vue' /* webpackChunkName: "pages/signup/step1" */))
const _54732228 = () => interopDefault(import('../pages/signup/step2.vue' /* webpackChunkName: "pages/signup/step2" */))
const _72bc1d6c = () => interopDefault(import('../pages/signup/step3/index.vue' /* webpackChunkName: "pages/signup/step3/index" */))
const _fea73f6e = () => interopDefault(import('../pages/signup/terms.vue' /* webpackChunkName: "pages/signup/terms" */))
const _84b65b38 = () => interopDefault(import('../pages/social/auth/index.vue' /* webpackChunkName: "pages/social/auth/index" */))
const _f94514d8 = () => interopDefault(import('../pages/social/signup/index.vue' /* webpackChunkName: "pages/social/signup/index" */))
const _a55c6ea8 = () => interopDefault(import('../pages/user/leave.vue' /* webpackChunkName: "pages/user/leave" */))
const _3550f75e = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _747c61cc = () => interopDefault(import('../pages/v1/imgViewer.vue' /* webpackChunkName: "pages/v1/imgViewer" */))
const _e8736faa = () => interopDefault(import('../pages/v1/payment.vue' /* webpackChunkName: "pages/v1/payment" */))
const _e7a9f3e4 = () => interopDefault(import('../pages/v1/resultPayment.vue' /* webpackChunkName: "pages/v1/resultPayment" */))
const _395b317d = () => interopDefault(import('../pages/contents/events/gayotop10/index.vue' /* webpackChunkName: "pages/contents/events/gayotop10/index" */))
const _5e92ce44 = () => interopDefault(import('../pages/payment/orders/step.vue' /* webpackChunkName: "pages/payment/orders/step" */))
const _73dea39e = () => interopDefault(import('../pages/payment/orders/step2.vue' /* webpackChunkName: "pages/payment/orders/step2" */))
const _7114eb6a = () => interopDefault(import('../pages/social/signup/step1/index.vue' /* webpackChunkName: "pages/social/signup/step1/index" */))
const _2d5b33c9 = () => interopDefault(import('../pages/social/signup/terms.vue' /* webpackChunkName: "pages/social/signup/terms" */))
const _65863534 = () => interopDefault(import('../pages/payment/orders/components/invoiceHistoryTable.vue' /* webpackChunkName: "pages/payment/orders/components/invoiceHistoryTable" */))
const _77766967 = () => interopDefault(import('../pages/signup/step3/components/input.vue' /* webpackChunkName: "pages/signup/step3/components/input" */))
const _75cd90a9 = () => interopDefault(import('../pages/social/signup/step1/components/input.vue' /* webpackChunkName: "pages/social/signup/step1/components/input" */))
const _31afd895 = () => interopDefault(import('../pages/contents/articles/detail/_id/index.vue' /* webpackChunkName: "pages/contents/articles/detail/_id/index" */))
const _07d82446 = () => interopDefault(import('../pages/contents/polls/detail/_id/index.vue' /* webpackChunkName: "pages/contents/polls/detail/_id/index" */))
const _4bd2bd2a = () => interopDefault(import('../pages/contents/polls/list/_status/index.vue' /* webpackChunkName: "pages/contents/polls/list/_status/index" */))
const _09403d9d = () => interopDefault(import('../pages/user/me/qna/_qnaNo.vue' /* webpackChunkName: "pages/user/me/qna/_qnaNo" */))
const _7b70f094 = () => interopDefault(import('../pages/contents/articles/_type/index.vue' /* webpackChunkName: "pages/contents/articles/_type/index" */))
const _2da54d14 = () => interopDefault(import('../pages/payment/orders/_type/index.vue' /* webpackChunkName: "pages/payment/orders/_type/index" */))
const _2ce46692 = () => interopDefault(import('../pages/projects/list/_type/index.vue' /* webpackChunkName: "pages/projects/list/_type/index" */))
const _40d21eb2 = () => interopDefault(import('../pages/shop/exhibit/_id/index.vue' /* webpackChunkName: "pages/shop/exhibit/_id/index" */))
const _c7d09fa0 = () => interopDefault(import('../pages/shop/list/_category/_cd/index.vue' /* webpackChunkName: "pages/shop/list/_category/_cd/index" */))
const _d8d75abe = () => interopDefault(import('../pages/projects/_id/index.vue' /* webpackChunkName: "pages/projects/_id/index" */))
const _1052b942 = () => interopDefault(import('../pages/projects/_id/index/cheer.vue' /* webpackChunkName: "pages/projects/_id/index/cheer" */))
const _631ce2b6 = () => interopDefault(import('../pages/projects/_id/index/notice.vue' /* webpackChunkName: "pages/projects/_id/index/notice" */))
const _1392978d = () => interopDefault(import('../pages/projects/_id/index/reward.vue' /* webpackChunkName: "pages/projects/_id/index/reward" */))
const _423ba307 = () => interopDefault(import('../pages/projects/_id/index/story.vue' /* webpackChunkName: "pages/projects/_id/index/story" */))
const _0395b16a = () => interopDefault(import('../pages/projects/_id/index/update/index.vue' /* webpackChunkName: "pages/projects/_id/index/update/index" */))
const _6c251cc6 = () => interopDefault(import('../pages/projects/_id/index/update/index/detail.vue' /* webpackChunkName: "pages/projects/_id/index/update/index/detail" */))
const _3ba1c49d = () => interopDefault(import('../pages/shop/_id/index.vue' /* webpackChunkName: "pages/shop/_id/index" */))
const _da4b1fd6 = () => interopDefault(import('../pages/projects/_id/payment.vue' /* webpackChunkName: "pages/projects/_id/payment" */))
const _654829fe = () => interopDefault(import('../pages/projects/_id/paymentStep2.vue' /* webpackChunkName: "pages/projects/_id/paymentStep2" */))
const _752fa742 = () => interopDefault(import('../pages/artists/_id/_category/index.vue' /* webpackChunkName: "pages/artists/_id/_category/index" */))
const _8cedf4ae = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/artists",
    component: _93d3f334,
    meta: {"name":"Artist","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"artist","onMenuRouteName":"Artist"},
    name: "artists"
  }, {
    path: "/chart",
    component: _0c4d3978,
    meta: {"name":"Main","components":{},"filters":{},"computed":{},"methods":{},"onMenuRouteName":"Chart"},
    name: "chart"
  }, {
    path: "/faq",
    component: _6b4a3a70,
    meta: {"name":"Example","directives":{},"filters":{},"middleware":"","computed":{},"methods":{},"fullSize":true,"pageTitle":"faq"},
    name: "faq"
  }, {
    path: "/lb-health",
    component: _0a10cb6a,
    meta: {"name":"LBHealthChecker","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{}},
    name: "lb-health"
  }, {
    path: "/login",
    component: _f99eae80,
    meta: {"name":"Login","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"login","onMenuRouteName":""},
    name: "login"
  }, {
    path: "/notice",
    component: _0207a211,
    meta: {"name":"NoticeList","directives":{},"components":{},"filters":{},"middleware":"","head":{},"computed":{},"methods":{}},
    name: "notice"
  }, {
    path: "/partnership",
    component: _7762db9b,
    meta: {"name":"Partnership","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"fullSize":false,"onMenuRouteName":"Partner"},
    name: "partnership"
  }, {
    path: "/password-retrieval",
    component: _42ae5b3b,
    meta: {"name":"PasswordRetrieval","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"passwordRetrieval","onMenuRouteName":""},
    name: "password-retrieval"
  }, {
    path: "/poca",
    component: _c74028d4,
    meta: {"name":"Poca","components":{},"computed":{},"methods":{},"fullSize":false,"onMenuRouteName":"PocaAlbum","pageTitle":"pocaalbum"},
    name: "poca"
  }, {
    path: "/powerofwhelm",
    component: _763e8bdc,
    meta: {"name":"Powerofwhelm","computed":{},"watch":{},"methods":{}},
    name: "powerofwhelm"
  }, {
    path: "/qna",
    component: _7eb48884,
    meta: {"name":"QnA","directives":{},"components":{},"computed":{},"methods":{},"pageTitle":"qna","onMenuRouteName":"","auth":true},
    name: "qna"
  }, {
    path: "/search",
    component: _a711bab8,
    meta: {"name":"Search","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"onMenuRouteName":"","pageTitle":"search"},
    name: "search"
  }, {
    path: "/specificCommercialLaw",
    component: _56ef4ebd,
    meta: {"name":"AboutPrivacy","directives":{},"components":{},"filters":{},"middleware":"","head":{},"computed":{},"methods":{}},
    name: "specificCommercialLaw"
  }, {
    path: "/userqna",
    component: _0fdc2fd0,
    meta: {"name":"UserQnA","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"inquiry","onMenuRouteName":"","auth":true},
    name: "userqna"
  }, {
    path: "/vote",
    component: _25c869a6,
    meta: {"name":"Vote","components":{},"pageTitle":"poll","onMenuRouteName":"Vote"},
    name: "vote"
  }, {
    path: "/about/marketing",
    component: _039af18a,
    meta: {"name":"AboutMarketing","directives":{},"components":{},"filters":{},"middleware":"","methods":{},"pageTitle":"aboutMarketing","onMenuRouteName":""},
    name: "about-marketing"
  }, {
    path: "/about/privacy",
    component: _1b3b9add,
    meta: {"name":"AboutPrivacy","directives":{},"components":{},"filters":{},"middleware":"","head":{},"methods":{}},
    name: "about-privacy"
  }, {
    path: "/about/terms",
    component: _4cbe96c8,
    meta: {"name":"AboutTerms","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"aboutTerms","onMenuRouteName":""},
    name: "about-terms"
  }, {
    path: "/payment/order",
    component: _3275be5c,
    meta: {"name":"NewProductOrder","components":{},"computed":{"phone":{},"paymentType":{},"deliveryCode":{},"selectCountry":{},"zipCode":{},"address":{},"rewardPrice":{}},"methods":{}},
    name: "payment-order"
  }, {
    path: "/payment/paymentRefundStep",
    component: _42d3d5d0,
    meta: {"name":"PaymentRefundStep","computed":{},"watch":{},"methods":{},"auth":true},
    name: "payment-paymentRefundStep"
  }, {
    path: "/payment/paymentRefundStep2",
    component: _1777916c,
    meta: {"name":"PaymentRefundStep","computed":{},"watch":{},"methods":{},"auth":true},
    name: "payment-paymentRefundStep2"
  }, {
    path: "/popup/invoiceHistory",
    component: _c112b63a,
    meta: {"name":"InvoiceHistory","components":{},"computed":{},"methods":{},"pageTitle":"deliveryTracking","auth":true},
    name: "popup-invoiceHistory"
  }, {
    path: "/projects/paymentStep3",
    component: _4d8cfe66,
    meta: {"name":"PaymentStep2","computed":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "projects-paymentStep3"
  }, {
    path: "/projects/updates",
    component: _7fa0c8a4,
    meta: {"name":"Search","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"update","onMenuRouteName":""},
    name: "projects-updates"
  }, {
    path: "/projects/wishlist",
    component: _395c2a11,
    meta: {"name":"Wishlist","components":{},"computed":{},"methods":{},"auth":true,"onMenuRouteName":"Wishlist","fullSize":true,"pageTitle":"wishlist"},
    name: "projects-wishlist"
  }, {
    path: "/projects/wishlist_origin",
    component: _54e392c4,
    meta: {"name":"Wishlist","components":{},"head":{"title":"Shop | K-POP Makestar"},"computed":{},"methods":{},"auth":true,"onMenuRouteName":"Wishlist","fullSize":true},
    name: "projects-wishlist_origin"
  }, {
    path: "/result/change-email",
    component: _639769f0,
    meta: {"name":"PasswordRetrievalResult","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"passwordRetrieval","onMenuRouteName":""},
    name: "result-change-email"
  }, {
    path: "/result/change-password",
    component: _666f4c5f,
    meta: {"name":"PasswordRetrievalResult","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"passwordRetrieval","onMenuRouteName":""},
    name: "result-change-password"
  }, {
    path: "/result/duplication-email",
    component: _448aca44,
    meta: {"name":"DuplicationEmail","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"","onMenuRouteName":""},
    name: "result-duplication-email"
  }, {
    path: "/result/invalid-email",
    component: _177add66,
    meta: {"name":"PasswordRetrievalResult","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"invalidEmail","onMenuRouteName":""},
    name: "result-invalid-email"
  }, {
    path: "/result/leaveres",
    component: _4ef681d2,
    meta: {"name":"LeaveRes","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"회원탈퇴","onMenuRouteName":"","fullSize":true},
    name: "result-leaveres"
  }, {
    path: "/result/password-retrieval",
    component: _12b7180b,
    meta: {"name":"PasswordRetrievalResult","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"passwordRetrieval","onMenuRouteName":""},
    name: "result-password-retrieval"
  }, {
    path: "/shop/B2BAccessOnly",
    component: _7d2bbcc2,
    meta: {"name":"B2BAccessOnly","components":{},"computed":{},"methods":{},"fullSize":false,"onMenuRouteName":"B2BAccessOnly"},
    name: "shop-B2BAccessOnly"
  }, {
    path: "/shop/cart",
    component: _03bf4470,
    meta: {"name":"Cart","components":{},"computed":{},"methods":{},"auth":true,"onMenuRouteName":"Cart","fullSize":true,"pageTitle":"cart"},
    name: "shop-cart"
  }, {
    path: "/shop/payment",
    component: _24fbafe6,
    meta: {"name":"Payment","computed":{},"watch":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "shop-payment"
  }, {
    path: "/shop/payment2",
    component: _7a91f63c,
    meta: {"name":"ShopStep","components":{},"computed":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "shop-payment2"
  }, {
    path: "/shop/products",
    component: _4f848698,
    meta: {"name":"ShopProducts","directives":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"onMenuRouteName":"Shop","fullSize":true},
    name: "shop-products"
  }, {
    path: "/signup/send-auth",
    component: _9b29ba5c,
    meta: {"name":"SendAuth","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{}},
    name: "signup-send-auth"
  }, {
    path: "/signup/send-email",
    component: _6e8672c5,
    meta: {"name":"SignUp","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-send-email"
  }, {
    path: "/signup/step1",
    component: _54650aa7,
    meta: {"name":"SignUpStep1","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-step1"
  }, {
    path: "/signup/step2",
    component: _54732228,
    meta: {"name":"SignUpStep2","directives":{},"filters":{},"middleware":"","props":{},"computed":{},"watch":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-step2"
  }, {
    path: "/signup/step3",
    component: _72bc1d6c,
    meta: {"name":"Step2","directives":{},"filters":{},"components":{},"middleware":"","props":{},"computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-step3"
  }, {
    path: "/signup/terms",
    component: _fea73f6e,
    meta: {"name":"SignUpTerms","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-terms"
  }, {
    path: "/social/auth",
    component: _84b65b38,
    meta: {"name":"SocialAuthPopup","methods":{}},
    name: "social-auth"
  }, {
    path: "/social/signup",
    component: _f94514d8,
    meta: {"name":"SocialSignup","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "social-signup"
  }, {
    path: "/user/leave",
    component: _a55c6ea8,
    meta: {"name":"Leave","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"leave","onMenuRouteName":"","fullSize":true,"auth":true},
    name: "user-leave"
  }, {
    path: "/user/profile",
    component: _3550f75e,
    meta: {"name":"UserProfile","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"profile","onMenuRouteName":"","fullSize":true,"auth":true},
    name: "user-profile"
  }, {
    path: "/v1/imgViewer",
    component: _747c61cc,
    meta: {"name":"ImgViewer"},
    name: "v1-imgViewer"
  }, {
    path: "/v1/payment",
    component: _e8736faa,
    meta: {"name":"Payment"},
    name: "v1-payment"
  }, {
    path: "/v1/resultPayment",
    component: _e7a9f3e4,
    meta: {"name":"ResultPayment","computed":{},"methods":{}},
    name: "v1-resultPayment"
  }, {
    path: "/contents/events/gayotop10",
    component: _395b317d,
    meta: {"name":"GayoTop10","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"GayoTop10","onMenuRouteName":"GayoTop10","fullSize":true},
    name: "contents-events-gayotop10"
  }, {
    path: "/payment/orders/step",
    component: _5e92ce44,
    meta: {"name":"PaymentOrdersDetail","components":{},"computed":{},"watch":{},"methods":{},"auth":true,"fullSize":false,"pageTitle":"orderDetail"},
    name: "payment-orders-step"
  }, {
    path: "/payment/orders/step2",
    component: _73dea39e,
    meta: {"name":"PaymentOrdersDetail","components":{},"computed":{},"methods":{},"auth":true,"fullSize":false},
    name: "payment-orders-step2"
  }, {
    path: "/social/signup/step1",
    component: _7114eb6a,
    meta: {"name":"Step2","directives":{},"filters":{},"components":{},"middleware":"","props":{},"computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "social-signup-step1"
  }, {
    path: "/social/signup/terms",
    component: _2d5b33c9,
    meta: {"name":"SocialSignUpTerms","computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "social-signup-terms"
  }, {
    path: "/payment/orders/components/invoiceHistoryTable",
    component: _65863534,
    meta: {"props":["invoiceList","dlvy","trackingUrl"],"computed":{},"methods":{}},
    name: "payment-orders-components-invoiceHistoryTable"
  }, {
    path: "/signup/step3/components/input",
    component: _77766967,
    meta: {"props":{"validRegexResult":{},"validRegex":{},"value":{"default":""},"placeholder":{"default":""},"key1":{"default":""},"componentType":{"default":"text"},"max":{"default":0}},"watch":{},"methods":{}},
    name: "signup-step3-components-input"
  }, {
    path: "/social/signup/step1/components/input",
    component: _75cd90a9,
    meta: {"props":{"validRegexResult":{},"validRegex":{},"value":{"default":""},"placeholder":{"default":""},"key1":{"default":""},"componentType":{"default":"text"},"max":{"default":0}},"watch":{},"methods":{}},
    name: "social-signup-step1-components-input"
  }, {
    path: "/contents/articles/detail/:id",
    component: _31afd895,
    meta: {"name":"EventDetail","components":{},"computed":{},"methods":{},"fullSize":true},
    name: "contents-articles-detail-id"
  }, {
    path: "/contents/polls/detail/:id",
    component: _07d82446,
    meta: {"name":"PollsDetail","components":{},"computed":{},"methods":{}},
    name: "contents-polls-detail-id"
  }, {
    path: "/contents/polls/list/:status",
    component: _4bd2bd2a,
    meta: {"name":"Polls","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"onMenuRouteName":"Event","fullSize":true},
    name: "contents-polls-list-status"
  }, {
    path: "/user/me/qna/:qnaNo?",
    component: _09403d9d,
    meta: {"name":"QnADetail","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"qnaInfo","onMenuRouteName":"","auth":true},
    name: "user-me-qna-qnaNo"
  }, {
    path: "/contents/articles/:type",
    component: _7b70f094,
    meta: {"name":"Articles","directives":{},"filters":{},"components":{},"middleware":"","computed":{},"methods":{},"onMenuRouteName":"Event"},
    name: "contents-articles-type"
  }, {
    path: "/payment/orders/:type",
    component: _2da54d14,
    meta: {"name":"Orders","components":{},"computed":{},"methods":{},"auth":true,"fullSize":true,"pageTitle":"payments"},
    name: "payment-orders-type"
  }, {
    path: "/projects/list/:type",
    component: _2ce46692,
    meta: {"name":"ProjectArtist","components":{},"computed":{},"methods":{},"onMenuRouteName":"Project","fullSize":true,"pageTitle":"project"},
    name: "projects-list-type"
  }, {
    path: "/shop/exhibit/:id",
    component: _40d21eb2,
    meta: {"components":{},"filters":{},"head":{"title":"Shop | K-POP Makestar","fullSize":true},"computed":{},"methods":{},"onMenuRouteName":"Shop","fullSize":true},
    name: "shop-exhibit-id"
  }, {
    path: "/shop/list/:category?/:cd",
    component: _c7d09fa0,
    meta: {"name":"ShopProducts","components":{},"computed":{},"watch":{},"methods":{},"onMenuRouteName":"Shop","fullSize":true,"pageTitle":"shop"},
    name: "shop-list-category-cd"
  }, {
    path: "/projects/:id",
    component: _d8d75abe,
    meta: {"name":"Index","components":{},"computed":{},"methods":{},"fullSize":true},
    name: "projects-id",
    children: [{
      path: "cheer",
      component: _1052b942,
      name: "projects-id-index-cheer"
    }, {
      path: "notice",
      component: _631ce2b6,
      name: "projects-id-index-notice"
    }, {
      path: "reward",
      component: _1392978d,
      name: "projects-id-index-reward"
    }, {
      path: "story",
      component: _423ba307,
      name: "projects-id-index-story"
    }, {
      path: "update",
      component: _0395b16a,
      name: "projects-id-index-update",
      children: [{
        path: "detail",
        component: _6c251cc6,
        name: "projects-id-index-update-index-detail"
      }]
    }]
  }, {
    path: "/shop/:id",
    component: _3ba1c49d,
    meta: {"name":"","filters":{},"computed":{},"components":{},"watch":{"undefined":{}},"methods":{}},
    name: "shop-id"
  }, {
    path: "/projects/:id?/payment",
    component: _da4b1fd6,
    meta: {"name":"Payment","components":{},"computed":{},"watch":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "projects-id-payment"
  }, {
    path: "/projects/:id?/paymentStep2",
    component: _654829fe,
    meta: {"name":"PaymentStep2","components":{},"computed":{},"watch":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "projects-id-paymentStep2"
  }, {
    path: "/artists/:id/:category",
    component: _752fa742,
    meta: {"name":"Artist","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"artists","onMenuRouteName":"Artist"},
    name: "artists-id-category"
  }, {
    path: "/",
    component: _8cedf4ae,
    meta: {"name":"Home","directives":{},"components":{},"filters":{},"computed":{},"watch":{},"methods":{},"fullSize":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
