import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { applyVueInReact } from 'vuereact-combined'
import VRouterLink from '../../../../components/VRouterLink'
const VVRouterLink = applyVueInReact(VRouterLink)

import { CardShopImage } from './CardShopImage'
import { CardShopPrice } from './CardShopPrice'
import { CardShopBadge } from './CardShopBadge'
import { BadgePromotion } from '~/react'
import { BadgeNew } from '~/react'
import { CardTitle } from '../CardTitle'
import { CardDescription } from '../CardDescription'
import { B2Medium, C1Medium, B2Bold, C1Bold } from '~/react/Foundation/Typography'
import { gray } from '~/react/Foundation/Color'
import codeConst from '~/utils/codeConst'
import { getNotAvailableForSaleText } from '~/utils/shop'
import i18nContext from '../../../i18nContext'
import { ShopDdayBadge } from '~/react/shop/ShopDdayBadge'
import { ShopSaleCount } from '~/react/shop/ShopSaleCount'

const { SALE_STAT, PRODUCT_TYPE } = codeConst

const DateCountContainer = styled.div({}, ({}) => {
  const baseStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DEDEDE',
    paddingBottom: '8px',
    margin: '8px 8px 0 8px'
  }

  const mobileStyle = {
    margin: '8px 6px 0 6px',
    paddingBottom: '6px'
  }
  return {
    ...baseStyle,
    ...mobileStyle
  }
})

const CardShopContainer = styled.div({}, ({}) => {
  const baseStyle = {
    cursor: 'pointer',
    width: '272px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }

  const mobileStyle = {
    width: '100%',
    minHeight: '100%'
  }

  return {
    ...baseStyle,
    ...mobileStyle
  }

})

const CardShopInfoWrapper = styled.div({}, ({}) => {
  const baseStyle = {
    // .item-info
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'flex-start',
    padding: '8px 8px 12px 8px'
  }

  const mobileStyle = {
    // .item-info-mobile
    padding: '8px 6px 12px 6px'
  }

  return {
    ...baseStyle,
    ...mobileStyle
  }
})

const RecomBadgeWrapper = styled.div({}, ({}) => {
  const baseStyle = {
    marginBottom: '6px'
  }

  const mobileStyle = {
    marginBottom: '4px'
  }

  return {
      ...baseStyle,
      ...mobileStyle
    }
})

const CardTitleDescWrapper = styled.div({}, ({ size }) => {
  const baseStyle = {
    marginBottom: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '2px'
  }

  const mobileStyle = {
    marginBottom: (size === 'medium') ? '6px' : '4px',
    ...(size === 'medium' && {gap: '4px'})
  }

  return {
      ...baseStyle,
      ...mobileStyle
    }
})

const CardShopPriceWrapper = styled.div({}, ({ size }) => {
  const baseStyle = {
    marginBottom: '8px'
  }

  const mobileStyle = {
    marginBottom: '6px'
  }

  return {
    ...baseStyle,
    ...mobileStyle
  }
})

const SoldoutTextB2Medium = styled(B2Medium)`
  color: ${gray[80]};
`

const SoldoutTextC1Medium = styled(C1Medium)`
  color: ${gray[80]};
`

/**
 * @typedef CardShopProps
 * @property {string} imageurl 이미지 주소
 * @property {boolean} iconnew 신규 상품 여부
 * @property {boolean} recomyn 추천 상품 여부
 * @property {string} pdnm 상품 이름
 * @property {string} pdcd 상품 코드
 * @property {string} ctgrnm 카테고리 이름
 * @property {{dcRatio: string, salePrice:string}} formattedprice 가격, 할인율
 * @property {array} badgelist 뱃지 목록
 * @property {(key: string) => void} clickcallback 카드 클릭 시 callback
 * @property {'small' | 'medium'} size 사이즈
 * @property {SALE_STAT} saleStatus 상품 판매상태
 * @property {boolean} isStarPartnersOnly B2B 특전용 가격 미노출 여부
 * @property {PRODUCT_TYPE} pdType 상품 타입
 * @property {{displaySaleTerm: boolean, saleEndDate: string}} [saleTermData] 판매 기간 관련
 * @property {{displaySaleQuantity: boolean, quantity: number}} [saleQuantityData] 판매 수량 관련
 */

/**
 * @param {CardShopProps}
 */
export function CardShop({
    imageurl,
    iconnew,
    recomyn,
    pdnm,
    pdcd,
    ctgrnm,
    dcRatio,
    price,
    salePrice,
    dcFee,
    currency,
    dcDiv,
    isDiscount,
    badgelist,
    clickcallback,
    size = 'small',
    saleStatus = SALE_STAT.SALE,
    isStarPartnersOnly = false,
    pdType,
    saleTermData,
    saleQuantityData,
    ...props
  }) {
  const i18n = useContext(i18nContext)

  const renderCardTitleDesc = () => {
    return (
      <CardTitleDescWrapper size={size}>
        <CardTitle title={pdnm}/>
        <CardDescription description={ctgrnm} />
      </CardTitleDescWrapper>
    )
  }

  const renderPrice = () => {
    return (
      <CardShopPriceWrapper size={size}>
        <CardShopPrice
          dcRatio={dcRatio}
          price={price}
          salePrice={salePrice}
          dcFee={dcFee}
          currency={currency}
          dcDiv={dcDiv}
          isDiscount={isDiscount}
        >
        </CardShopPrice>
      </CardShopPriceWrapper>
    )
  }
  const renderBadges = () => {
    return (
      (badgelist && badgelist.length > 0) && (
        <CardShopBadge badgeList={badgelist}>
        </CardShopBadge>
      )
    )
  }

  const renderStarPartnersOnlyText = () => {
    const NAText = i18n.t('shop.exclusiveItem')
    if (size === 'small') {
      return <C1Bold>{NAText}</C1Bold>
    }
    return <B2Bold>{NAText}</B2Bold>
  }

  const renderNAText = () => {
    const NAText = i18n.t(getNotAvailableForSaleText(saleStatus))
    if (size === 'small') {
      return <SoldoutTextC1Medium>{NAText}</SoldoutTextC1Medium>
    }
    return <SoldoutTextB2Medium>{NAText}</SoldoutTextB2Medium>
  }

  const showDdayBadge = () => {
    const isAllowedType = (pdType === PRODUCT_TYPE.CHINAGROUPBUY) || (pdType === PRODUCT_TYPE.B2B)
    return isAllowedType && saleTermData?.displaySaleTerm && saleTermData?.saleEndDate
  }

  const showSaleCount = () => {
    const isAllowedType = pdType === PRODUCT_TYPE.CHINAGROUPBUY
    return isAllowedType && saleQuantityData?.displaySaleQuantity
  }

  return (
    <CardShopContainer {...props}>
      <VVRouterLink url={`/shop/${pdcd}`} style={{ textDecoration: 'none' }}>
        <CardShopImage imageUrl={imageurl} iconNew={iconnew} size={size} saleStatus={saleStatus} >
          { iconnew && <BadgeNew /> }
        </CardShopImage>
        {(showDdayBadge() || showSaleCount()) && (
          <DateCountContainer>
            {showDdayBadge() && <ShopDdayBadge endDate={saleTermData.saleEndDate} saleStatus={saleStatus} />}
            {showSaleCount() && <ShopSaleCount count={saleQuantityData.quantity} />}
          </DateCountContainer>
        )}
        <CardShopInfoWrapper>
          {recomyn && (
            <RecomBadgeWrapper>
              <BadgePromotion />
            </RecomBadgeWrapper>
          )}
          {renderCardTitleDesc()}
          {isStarPartnersOnly
            ? renderStarPartnersOnlyText()
            : saleStatus === SALE_STAT.SALE ? (
              <>
                {renderPrice()}
                {renderBadges()}
              </>
            ) : renderNAText()
          }

        </CardShopInfoWrapper>
      </VVRouterLink>
    </CardShopContainer>
  )
}


